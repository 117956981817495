import { BaseService, Service } from "/@/cool";
@Service("dealerAllocation")
class dealerAllocation extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["page"] = prefix + "page";
		this.permission["second"] = prefix + "second";
	}
	
	second(parent_id: number) {
		return this.request({
			url: "/second",
			method: "POST",
			data: parent_id 
		});
	}
}
export default dealerAllocation;
