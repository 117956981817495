import { BaseService, Service } from "/@/cool";
@Service("shopAllocationLog")
class shopAllocationLog extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["page"] = prefix + "page";
	}
}
export default shopAllocationLog;
