import { BaseService, Service } from "/@/cool";
@Service("worldArea")
class WorldArea extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["info"] = prefix + "info";
		this.permission["list"] = prefix + "list";
		this.permission["page"] = prefix + "page";
		this.permission["delete"] = prefix + "delete";
		this.permission["countries"] = prefix + "countries";
		this.permission["states"] = prefix + "states";
		this.permission["cities"] = prefix + "cities";
		this.permission["regions"] = prefix + "regions";
		this.permission["getChinaId"] = prefix + "getChinaId";
	}

	countries() {
		return this.request({
			url: "/countries",
			method: "POST",
			data: {}
		});
	}

	states(data: any) {
		return this.request({
			url: "/states",
			method: "POST",
			data
		});
	}

	cities(data: any) {
		return this.request({
			url: "/cities",
			method: "POST",
			data
		});
	}

	regions(data: any) {
		return this.request({
			url: "/regions",
			method: "POST",
			data
		});
	}

	getChinaId() {
		return this.request({
			url: "/getChinaId",
			method: "GET"
		});
	}
}
export default WorldArea;
