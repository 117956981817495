import { BaseService, Service } from "/@/cool";
@Service("worldArea")
class WorldArea extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
	}

	countries() {
		return this.request({
			url: "/countries",
			method: "POST",
			data: {}
		});
	}

	states(data: any) {
		return this.request({
			url: "/states",
			method: "POST",
			data
		});
	}

	cities(data: any) {
		return this.request({
			url: "/cities",
			method: "POST",
			data
		});
	}

	regions(data: any) {
		return this.request({
			url: "/regions",
			method: "POST",
			data
		});
	}
	getChinaId() {
		return this.request({
			url: "/getChinaId",
			method: "GET"
		});
	}
}
export default WorldArea;
