import { BaseService, Service } from "/@/cool";
// const localHttpPrefix = "https://192.168.88.2:8200";

@Service("shopExternalOrder")
class ShopExternalOrder extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["createCustomerAndPid"] = prefix + "createCustomerAndPid";
		this.permission["updateCoverForExteralOrder"] = prefix + "updateCoverForExteralOrder";
		this.permission["checkExternalOrderShopMoney"] = prefix + "checkExternalOrderShopMoney";
		// this.permission["addUploadQueue"] = prefix + "addUploadQueue";
		// this.permission["photos"] = prefix + "photos";
		// this.permission["check"] = prefix + "check";
		// this.permission["shakeCheckImage"] = prefix + "shakeCheckImage";
		// this.permission["preview"] = prefix + "preview";
	}

	createCustomerAndPid(data: any) {
		return this.request({
			url: "/createCustomerAndPid",
			method: "POST",
			data
		});
	}

	updateCoverForExteralOrder(data:any){
		return this.request({
			url: "/updateCoverForExteralOrder",
			method: "POST",
			data
		});
	}

	checkExternalOrderShopMoney(data:any){
		return this.request({
			url: "/checkExternalOrderShopMoney",
			method: "POST",
			data
		});
	}
}
export default ShopExternalOrder;
