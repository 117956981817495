import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";
import * as Dict from "/$/takephoto/dict/takephoto";

interface Takephoto {
	[key: string]: any;
}

// 本地缓存
const data = storage.info();

export const useTakephotoStore = defineStore("takephoto", function () {
	// 用户信息
	const info = ref<Takephoto>(data.takephotoInfo || {});

	const timeout = 2000 * 2;
	let timer: any = null;
	// 设置用户信息
	function set(value: any) {
		info.value = value;
		storage.set("takephotoInfo", value);
	}

	function get(equipmentId: Number): string {
		return info.value["takephotoStatus_" + equipmentId];
	}

	//判断是否在上传中
	function isUploadingOss(equipmentId: Number): Boolean {
		let code = get(equipmentId);
		console.log("判断当前是否在上传中的code", code);
		if (code == Dict.UPLOADING) {
			return true;
		}
		return false
	}

	function getTimeout(equipmentId: Number): Boolean {
		return !!info.value["timeout_" + equipmentId];
	}

	function setIsTimeout(equipmentId: Number, value: any) {
		const key = "timeout_" + equipmentId;
		info.value[key] = value;
		storage.set("takephotoInfo", info.value);
		console.log("setTimeout", info.value[key], info.value);
	}

	// 清除用户
	function clear() {
		storage.remove("takephotoInfo");
		info.value = {};
	}

	function setStatus(key: any, value: any) {
		info.value[key] = value;
		storage.set("takephotoInfo", info.value);
	}

	function resetTimeout(equipmentId: Number) {
		setIsTimeout(equipmentId, false);
	}

	function isUploading(equipmentId: Number): Boolean {
		return Dict.UploadingWithLocal.includes(get(equipmentId));
	}

	// 云端上传
	function isRemoteUploading(equipmentId: Number): Boolean {
		const value = get(equipmentId);
		return value == Dict.UPLOADING;
	}

	// 同步到主控
	function isLocalUploading(equipmentId: Number): Boolean {
		return Dict.LocalUploading.includes(get(equipmentId));
	}

	function isTimeout(equipmentId: Number): Boolean {
		return getTimeout(equipmentId);
	}

	// 是否可以准备拍照
	function canReadyTake(equipmentId: Number): Boolean {
		const value = get(equipmentId);
		return Dict.ReadyTake.includes(value) || value == "" || !value;
	}

	// 是否可以拍照
	function canTakephoto(equipmentId: Number): Boolean {
		const value = get(equipmentId);
		return Dict.Takephoto.includes(value) || value == "" || !value;
	}

	function doTimeoutCheck(equipmentId: Number) {
		clearTimeout(timer);
		timer = setTimeout(() => {
			console.log("doTimeoutCheck", equipmentId);
			setIsTimeout(equipmentId, true);
		}, timeout);
	}

	function showVideo(equipmentId: Number): Boolean {
		const value = get(equipmentId);
		// 相机同步检查
		if (Dict.VideoCheck.includes(value)) {
			switch (value) {
				case Dict.READY:
				case Dict.CAMERA_SYNCED:
					console.log("showVideo", equipmentId);
					if (isTimeout(equipmentId)) {
						return false;
					}
					doTimeoutCheck(equipmentId);
					break;
				case Dict.START:
					clearTimeout(timer);
					setIsTimeout(equipmentId, false);
					break;
				case Dict.UPLOADING:
				case Dict.UPLOADED:
					break;
				default:
					break;
			}

			return true;
		}
		return false;
	}

	return {
		info,
		setStatus,
		set,
		get,
		showVideo,
		clear,
		isUploading,
		resetTimeout,
		isLocalUploading,
		isRemoteUploading,
		canTakephoto,
		canReadyTake,
		isUploadingOss,
	};
});
