import { BaseService, Service } from "/@/cool";
@Service("attributeVersion")
class AttributeVersion extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["info"] = prefix + "info";
		this.permission["list"] = prefix + "list";
		this.permission["page"] = prefix + "page";
		this.permission["delete"] = prefix + "delete";
		this.permission["modelSizeList"] = prefix + "modelSizeList";
	}
	modelSizeList(data: any) {
		return this.request({
			url: "/modelSizeList",
			method: "post",
			data
		});
	}
}
export default AttributeVersion;
