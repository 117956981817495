import _ from "lodash";
import dayjs from "dayjs";
import useClipboard from "vue-clipboard3";
import {ElMessage} from "element-plus";
import {useCool} from "/@/cool";

const {service} = useCool();

export function useTools() {
	//手动更新upsert的数据
	/**
	 * @param findTag 通过哪个属性键查找到对应数组的属性
	 * @param tagValue 匹配哪个属性键的等于tagValue.找到对应数组 index
	 * @param keyName 给那个数组的对象增加属性的键名
	 * @param options keyName对应的新值
	 * @returns
	 */
	const setUpsertSelectComponentOption = (
		origin: any,
		findTag: any,
		tagValue: any,
		keyName: any,
		options: any
	) => {
		const index = _.findIndex(origin, function (o: any) {
			return o[findTag] == tagValue;
		});
		origin[index].component[keyName] = options;
		return origin;
	};
	//手动更新 table的数据
	/**
	 * @param findTag 通过哪个属性键查找到对应数组的属性
	 * @param tagValue 匹配哪个属性键的等于tagValue.找到对应数组 index
	 * @param keyName 给那个数组的对象增加属性的键名
	 * @param options keyName对应的新值
	 * @returns
	 */
	const setTableSelectDictOption = (
		origin: any,
		findTag: any,
		tagValue: any,
		keyName: any,
		options: any
	) => {
		const index = _.findIndex(origin, function (o: any) {
			return o[findTag] == tagValue;
		});
		origin[index][keyName] = options;
		return origin;
	};
	// const goTo = (path: string, query: any) => {
	// 	router.push({ path, query });
	// };

	/**
	 *
	 * @param table
	 * @param upsert
	 * @param tagValue 对这个键赋值
	 * @param options 增加对应的新值
	 */
	const setTableUpsertOptions = (table: any, upsert: any, tagValue: any, options: any) => {
		setUpsertSelectComponentOption(upsert.items, "prop", tagValue, "options", options);
		setTableSelectDictOption(table.columns, "prop", tagValue, "dict", options);
	};

	return {setUpsertSelectComponentOption, setTableSelectDictOption, setTableUpsertOptions};
}

export function sleep(time: number) {
	return new Promise((resolve, reject) => {
		setTimeout(() => resolve("over"), time);
	});
}

export function copy(text: string) {
	const {toClipboard} = useClipboard();
	const _copy = async () => {
		try {
			await toClipboard(text);
			ElMessage.success("复制成功");
		} catch (e: any) {
			ElMessage.error(e.message);
		}
	};

	_copy();
}

export function exportFilenameWithDate(filename: string) {
	if (!filename) {
		return "报表-" + dayjs().format("YYYY-MM-DD");
	}

	return filename + "报表-" + dayjs().format("YYYY-MM-DD");
}

// 下载excel文件
export function downloadExcel(href: string, name: string) {
	const eleLink = document.createElement("a");
	eleLink.download = name;
	eleLink.href = href;
	eleLink.click();
	eleLink.remove();
}

//获取请求地址的后的参数
const getUrlParams = (value: any) => {
	// window.location.href 获取地址
	let url = window.location.href;
	let p = url.split("?")[1];
	let params = new URLSearchParams(p);
	return params.get(value);
};


//判断当前的URL是否有code 和 state 和 path 参数，说明是从微信授权跳转，这个时候要重定向回原页面
export function isWxReDirect(next: any) {
	const url = window.location.href;

	//是否要跳转到初始化检测页面的判断
	let stateValue = getUrlParams("state");
	const code = getUrlParams("code");
	if (code && stateValue && stateValue.indexOf("start_deviceAnd") > -1) {
		//替换 stateValue 中的 #/ 为空
		stateValue = stateValue.replace("#/", "");
		window.location.href = window.location.origin + "#/initCheck?code=" + code + "&state=" + stateValue;
		return;
	}

	//完成设备的调试通知，
	if (code && stateValue && stateValue.indexOf("complete_setupAnd") > -1) {

		//替换 stateValue 中的 #/ 为空
		stateValue = stateValue.replace("#/", "");
		// window.location.href = window.location.origin +"#/initCheck?"+"?code="+code+"&state="+stateValue;
		const form: any = {code: code, isNewParams: 1, state: "", shop_id: 0};
		const ArrState = stateValue.split("And");
		form.shop_id = ArrState[1];
		form.state = ArrState[0];
		window.location.href = window.location.origin + "#/takephoto?code=" + code + "&state=" + stateValue;
		return;
	}

	if (url.indexOf("code") == -1 || url.indexOf("state") == -1 || url.indexOf("path") == -1) {
		return false;
	}
	const state: any = getUrlParams("state");
	const arrState = state.split("-");
	//
	return next("/" + arrState[arrState.length - 1]);
}
