import { BaseService, Service } from "/@/cool";
@Service("user")
class User extends BaseService {
	constructor() {
		super();
		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["list"] = prefix + "list";
	}
}
export default User;
