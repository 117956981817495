export const READY = "ready_takephoto";
export const CAMERA_SYNCED = "camera_synced"; // 相机同步完成
export const IDLE = "idle"; // 空闲
export const START = "start_takephoto";
export const LOCAL_UPLOADING = "local_uploading"; // 本地上传中
export const LOCAL_UPLOADED = "local_uploaded"; // 本地上传完成
export const UPLOADING = "uploading"; // 云端上传中
export const UPLOADED = "uploaded"; // 云端上传完成

export const READY_SHOOT = "ready_shoot"; // 准备拍照
export const SHOOT = "shoot"; // 开始拍照
export const SHOOT_DONE = "shoot_done"; // 拍照完成
// export const UPLOADING = "uploading"; // 上传中
export const UPLOAD_DONE = "upload_done"; // 上传完成

export const FLOW_STATUS = "flow";

//
export const ReadyTake: any[] = [
	IDLE,
	CAMERA_SYNCED,
	LOCAL_UPLOADED,
	UPLOADING,
	UPLOADED,
	SHOOT_DONE,
	READY_SHOOT,
	UPLOAD_DONE
];
export const Takephoto: any[] = [
	CAMERA_SYNCED,
	READY_SHOOT,
	LOCAL_UPLOADED,
	UPLOADED,
	SHOOT_DONE,
	UPLOAD_DONE
];

export const LocalUploading: any[] = [START, SHOOT, LOCAL_UPLOADING];

export const UploadingWithLocal: any[] = [...LocalUploading, UPLOADING];

export const VideoCheck: any[] = [
	READY_SHOOT,
	READY,
	CAMERA_SYNCED,
	START,
	SHOOT,
	LOCAL_UPLOADING,
	UPLOADING
];
