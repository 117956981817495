import { BaseService, Service } from "/@/cool";
@Service("dealerSettleSetting")
class DealerSettleSetting extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["info"] = prefix + "info";
		this.permission["list"] = prefix + "list";
		this.permission["page"] = prefix + "page";
		this.permission["delete"] = prefix + "delete";
		this.permission["items"] = prefix + "items";
		this.permission["shopItems"] = prefix + "shopItems";
		this.permission["bind"] = prefix + "bind";
		this.permission["bindingInfo"] = prefix + "bindingInfo";
	}

	items(params:any) {
		return this.request({
			url: "/items",
			method: "POST",
			data: { ...params }
		});
	}
	bind(params:any) {
		return this.request({
			url: "/bind",
			method: "POST",
			data: { ...params }
		});
	}
	bindingInfo(params:any) {
		return this.request({
			url: "/bindingInfo",
			method: "POST",
			data: { ...params }
		});
	}
	shopItems(params:any) {
		return this.request({
			url: "/shopItems",
			method: "POST",
			data: { ...params }
		});
	}
}
export default DealerSettleSetting;
