import { BaseService, Service } from "/@/cool";
@Service("dealer")
class Dealer extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["info"] = prefix + "info";
		this.permission["list"] = prefix + "list";
		this.permission["page"] = prefix + "page";
		this.permission["delete"] = prefix + "delete";
		this.permission["export"] = prefix + "export";
		this.permission["first"] = prefix + "first";
		this.permission["allLevel"] = prefix + "allLevel";
		this.permission["userInfo"] = prefix + "userInfo";
		this.permission["dealerAll"] = prefix + "dealerAll";
	}

	first() {
		return this.request({
			url: "/first",
			method: "GET"
		});
	}
	dealerShop(params:any) {
		return this.request({
			url: "/dealerShop",
			method: "POST",
			data: { ...params }
		});
	}
	dealerAll(params: any) {
		return this.request({
			url: "/dealerAll",
			method: "POST",
			data: { ...params }
		});
	}
	allLevel(params: any) {
		return this.request({
			url: "/allLevel",
			method: "POST",
			data: { ...params }
		});
	}
	userInfo(params: any) {
		return this.request({
			url: "/userInfo",
			method: "POST",
			data: { ...params }
		});
	}

}
export default Dealer;
